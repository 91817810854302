<!-- //科技管理 -->
<template>
  <div>
    <div>
      <el-descriptions :title="plandDetail.name" border>
        <el-descriptions-item label="亮点">{{ plandDetail.shortTitle || '-' }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{ plandDetail.createTime || '-' }}</el-descriptions-item>
        <el-descriptions-item label="种植作物">{{ plandDetail.cropsACategoryName || '-' }}</el-descriptions-item>
        <el-descriptions-item label="作业亩产"
          >{{ plandDetail.cropsAPredictYield || '-' }}{{ plandDetail.cropsAPredictYield ? '斤' : '' }}</el-descriptions-item
        >
        <el-descriptions-item label="土壤特性的描述">
          <!-- <el-tooltip :content="plandDetail.soilProperties" placement="top-start">
            <span class="show-over" ref="content">{{ plandDetail.soilProperties }}</span>
          </el-tooltip> -->
          <descriptionsTooltip :value="plandDetail.soilProperties"></descriptionsTooltip>
        </el-descriptions-item>

        <el-descriptions-item label="积温要求"> {{ plandDetail.accumulatedTemp + '℃' || '-' }}</el-descriptions-item>
        <el-descriptions-item label="关联种植季">{{ getSeasonDetail(plandDetail.seasonId) || '-' }}</el-descriptions-item>
        <el-descriptions-item label="状态">{{ geeStatus(plandDetail.state) || '-' }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <el-row type="flex" justify="end" style="margin-bottom: 12px">
      <el-button v-if="!isDetail" type="primary" size="small" @click="(isEdit = false), (dialogVisible = true)">新建农事</el-button>
    </el-row>

    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <!-- <el-table-column prop="name" label="名称"> </el-table-column> -->
      <el-table-column prop="title" label="农事类型">
        <template slot-scope="scope">
          {{ geetStatus(scope.row.farmingStage, scope.row.farmingTypeId) }}
        </template>
      </el-table-column>
      <el-table-column prop="contractName" label="日期">
        <template slot-scope="scope">
          <div> 开始:{{ transformData(scope.row.beginDate) }} </div>
          <div> 结束:{{ transformData(scope.row.endDate) }} </div>
        </template>
      </el-table-column>
      <el-table-column prop="contractName" label="重要程度">
        <template slot-scope="scope">
          <el-rate :disabled="true" :max="10" v-model="scope.row.importanceLevel"></el-rate>
        </template>
      </el-table-column>
      <el-table-column prop="descriptions" label="描述" show-overflow-tooltip> </el-table-column>
      <el-table-column v-if="!isDetail" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="toEdit(scope.row)">编辑</el-button>
          <el-popconfirm title="确认删除?" @confirm="toDelete(scope.row.id)">
            <el-button slot="reference" type="text" size="small">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <createFarming :currentItem="currentItem" :dialogVisible.sync="dialogVisible" :isEdit="isEdit" @updateList="getList"> </createFarming>
  </div>
</template>

<script>
import createFarming from '../../../components/createFarming.vue';
import { plantingSubList, unpublishedService, publishedService, deletePlanting, getRequest, seasonList, farmingList } from '@/api/api.js';
import dayjs from 'dayjs';
import descriptionsTooltip from '@/components/descriptionsTooltip';
export default {
  components: {
    createFarming,
    descriptionsTooltip
  },
  data() {
    return {
      currentItem: {},
      dialogVisible: false,
      isEdit: false,
      title: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      contractName: '',
      searchParams: {},
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      activeName: '2',

      tableData: [],
      plandDetail: {},
      season: [],
      options1: []
    };
  },
  created() {
    this.$store.commit('set_breadList', [{ name: '农事操作' }]);
  },
  mounted() {
    farmingList()
      .then((e) => {
        this.options1 = e;
        this.getList();
        this.getSeason();
      })
      .catch((error) => {});
  },
  computed: {
    isDetail() {
      if (this.$route.query.state && this.$route.query.state == 2) {
        return true;
      }
      return false;
    }
  },
  methods: {
    transformData(data) {
      return dayjs(data).format('YYYY-MM-DD ');
    },
    geeStatus(state) {
      // 1待启用、2可用、3不可用、4已删除
      let val = '';
      switch (state) {
        case 1:
          val = '待启用';
          break;
        case 2:
          val = '可用';
          break;
        case 3:
          val = '不可用';
          break;
        case 4:
          val = '已删除';
          break;
        default:
          break;
      }
      return val;
    },
    getSeasonDetail(key) {
      let val = '';
      this.season.forEach((e) => {
        //console.log(e);
        if (e.id == key) {
          //console.log(e.name);
          val = e.name;
        }
      });
      return val;
    },
    getSeason() {
      seasonList()
        .then((res) => {
          this.season = res;
          this.getDetail();
        })
        .catch((error) => {});
    },
    getDetail() {
      getRequest('/api/operate/planting/scheme/view', { id: this.$route.query.id })
        .then((res) => {
          this.plandDetail = res;
        })
        .catch();
    },
    toDelete(id) {
      //console.log(id);
      let formData = new FormData();
      formData.append('id', id);
      deletePlanting(formData)
        .then((e) => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getList();
        })
        .catch((error) => {});
    },
    geetStatus(farmingStage, farmingTypeId) {
      let val = '';
      this.options1.forEach((e) => {
        if (e.stageCode == farmingStage) {
          e.farmingTypes.forEach((sub) => {
            if (sub.id == farmingTypeId) {
              val = sub.name;
            }
          });
        }
      });
      return val;
    },
    toDetail(item) {
      // this.$router.push({ name: 'detailService', query: { id: item.id } })
    },
    search() {
      this.searchParams = {
        title: this.title,
        contractName: this.contractName
      };
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    toEdit(item) {
      this.currentId = item.id;
      this.currentItem = item;
      this.isEdit = true;
      this.dialogVisible = true;
    },
    publish(item) {
      publishedService({ id: item.id })
        .then((res) => {
          this.$message({
            message: '发布成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },
    unpublished(item) {
      unpublishedService({ id: item.id })
        .then((res) => {
          this.$message({
            message: '取消发布成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },
    handleClick(row) {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      plantingSubList({
        plantingSchemeId: this.$route.query.id,
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ...this.searchParams
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    confirmApply(e) {
      //console.log(e);
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
.el-descriptions__body .el-descriptions__table {
  white-space: nowrap;
}
.el-descriptions-item__content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 8px;
}
</style>
