<template>
  <div class="map-container">
    <div class="total-num" v-if="totalNum">共{{ totalNum }}块地块</div>
    <div id="map"></div>
    <div class="zoom-tool-wrap">
      <div class="tool-btn" @click="zoomAdd">
        <span class="zn-zoom-icon icon iconfont icon-jiahao"></span>
      </div>
      <div class="tool-btn" @click="zoomCut">
        <span class="zn-zoom-icon icon iconfont icon-jianhao"></span>
      </div>
    </div>
    <div class="status-tips-wrap">
      <div class="tips-item" v-for="item in tipsArr" :key="item.id">
        <div :class="[item.id == 1 ? 'tips-box1' : item.id == 2 ? 'tips-box2' : item.id == 3 ? 'tips-box3' : 'tips-box4', 'tips-box']"></div>
        {{ item.name }}
      </div>
    </div>

    <!-- <el-dialog
      :modal="false"
      :width="'400px'"
      :center="true"
      title="选择障碍物"
      :show-close="false"
      :visible.sync="selObsDg"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="del-confirm-content">
        <el-select v-model="selObsType" placeholder="请选择" @change="obsChange">
          <el-option v-for="item in obsImages" :key="item.type" :label="item.name" :value="item.type"> </el-option>
        </el-select>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
import L from 'leaflet';
import 'leaflet-draw';
import '@/config/zoomControl.js';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import '@/config/leaflet.draw-cn.js';
import { googleMap, gaodeMap } from '@/config/map.js';
import * as utils from '@/utils/utils.js';
import { landAdd, landTaskState } from '@/api/plantingPlant/api.js';
export default {
  props: ['schemeFarmingId'],
  data() {
    return {
      marker: [], // 地图标记
      latlngs: [], //地块经纬度数组
      locLat: '33.446385', // 当前的维度,
      locLng: '114.071285', // 当前的经度
      polygon: '', //地块对象
      polygonArr: [], //地块集合数组
      polygonGroup: '', //地块集合图层
      lineColor1: '#B2FFB2', // 未开始的边框颜色
      fillColor1: '#B2FFB2', // 未开始的填充颜色
      lineColor2: '#1890FF', // 进行中的边框颜色
      fillColor2: '#1890FF', // 进行中的填充颜色
      lineColor3: '#5F98CC', // 已完成的边框颜色
      fillColor3: '#5F98CC', // 已完成的填充颜色
      lineColor4: '#BEC8DB', // 已忽略的边框颜色
      fillColor4: '#BEC8DB', // 已忽略的填充颜色
      landData: [],
      totalNum: 0,
      tipsArr: [
        {
          name: '未开始',
          id: 1
        },
        {
          name: '进行中',
          id: 2
        },
        {
          name: '已完成',
          id: 3
        },
        {
          name: '已忽略',
          id: 4
        }
      ]
    };
  },
  watch: {
    schemeFarmingId: {
      handler(val) {
        if (val) {
          // 获取种植任务的地块详情
          this.getLandTaskState();
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      // 地图初始化参数
      let initParam = {
        L,
        id: 'map',
        center: [this.locLat, this.locLng],
        mapType: googleMap,
        zoom: 12
      };
      // 初始化地图
      this.map = utils.initMap(initParam);
    },
    getLandTaskState() {
      let params = {
        schemeFarmingId: this.schemeFarmingId
      };
      landTaskState(params)
        .then(res => {
          this.stateData = res;
          this.totalNum = res.length || 0;
          this.showLands();
        })
        .catch(err => {
          console.error(err);
        });
    },
    // 展示所有地块
    showLands() {
      let stateData = this.stateData;
      if (stateData.length) {
        stateData.forEach(item => {
          let lineColor = this.lineColor1;
          let fillColor = this.fillColor1;
          let taskState = item.state;
          if (taskState == 2) {
            lineColor = this.lineColor2;
            fillColor = this.fillColor2;
          } else if (taskState == 3) {
            lineColor = this.lineColor3;
            fillColor = this.fillColor3;
          } else if (taskState == 4) {
            lineColor = this.lineColor4;
            fillColor = this.fillColor4;
          }
          let landInfoVO = item.landInfoVO;
          let boundaries = landInfoVO.boundaries || [];
          // 绘制地块
          boundaries.forEach((bound, index) => {
            let latLngs = [];
            bound.biasedBoundary.forEach(point => {
              latLngs.push([point.latitude, point.longitude]);
            });
            let polygon = L.polygon(latLngs, {
              color: lineColor,
              fillColor: fillColor,
              weight: 1,
              opacity: 1,
              fillOpacity: 0.3,
              _type: 1, // 地块
              _id: bound.id
            })
              .addTo(this.map)
              .on('click', e => {
                console.log(7897897);
                this.clickLandPolygon(e.latlng, item);
              });
            if (index == 0) {
              let bounds = polygon.getBounds();
              this.map.fitBounds(bounds);
            }
            // var popup = L.divIcon({
            //   className: `land-name`,
            //   html: `${landInfoVO.name}`
            // });
            // let center = polygon.getCenter();
            // let markers = L.marker(center, { icon: popup }).addTo(this.map);
            this.polygonArr.push(polygon);
          });
        });
        this.polygonGroup = L.layerGroup(this.polygonArr);
        this.map.addLayer(this.polygonGroup);
      }
    },
    clickLandPolygon(latlng, item) {
      let landInfoVO = item.landInfoVO;
      let stateName = '未开始';
      if (item.state == 2) {
        stateName = '进行中';
      } else if (item.state == 3) {
        stateName = '已完成';
      } else if (item.state == 4) {
        stateName = '已忽略';
      }
      var popContent =
        '<div class="landInfoContent">' +
        `<div class="til">${landInfoVO.name}</div>` +
        `<p>状态：<span>${stateName}</span></p>` +
        `<p>作物：<span>${landInfoVO.cropsACategoryName || '-'}</span></p>` +
        `<p>种植面积：<span>${landInfoVO.plantArea}亩</span></p>` +
        `</div>`;
      L.popup({ className: `landPopInfo` })
        .setLatLng(latlng)
        .setContent(popContent)
        .openOn(this.map);
    },
    zoomAdd() {
      this.map && this.map.setZoom(this.map.getZoom() + 1);
    },
    zoomCut() {
      this.map && this.map.setZoom(this.map.getZoom() - 1);
    },

    // 定位到当前位置
    dingwei(lat = '39.89', lng = '116.45') {
      // 指定中心点
      let center = L.latLng(lat, lng);
      var locationIcon = L.icon({
        iconUrl: 'static/images/location.png',
        iconSize: [20, 24],
        iconAnchor: [10, 24]
      });
      // 如果当前位置marker已标记则删除重新标记
      if (this.locIconMarker) {
        this.location.remove();
      }
      // 视图位置的icon标志
      this.locIconMarker = L.marker(center, { icon: locationIcon }).addTo(this.map);
      this.resetMapView(center);
    },
    // 更新视图视角
    resetMapView(center, zoom = 16) {
      this.map.setView(center, zoom);
    },

    // 获取位置信息 (js-native) 异步
    getLocation(action) {
      if (!action) {
        // 获取位置信息 (js-native) 异步
        this.$dsBridge.call('getLocation', null, res => {
          let { latitude, longitude } = JSON.parse(res).data;
          this.locLat = latitude;
          this.locLng = longitude;
          // 初始化地图
          this.initMap();
        });
      } else {
        // 获取位置信息 (js-native) 异步
        this.$dsBridge.call('getLocation', null, res => {
          let { latitude, longitude } = JSON.parse(res).data;
          // 初始化地图
          this.dingwei(latitude, longitude);
        });
      }
    }
  },
  destroyed() {
    this.map && this.map.remove();
  }
};
</script>
<style scoped>
    #map >>> .landInfoContent .til {
      height: 14px;
      font-size: 14px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      line-height: 14px;
    }
    #map >>> .landInfoContent p {
        margin: 8px 0;
        font-size: 12px;
        line-height: 12px;
        height: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
      }
      #map >>> .land-name {
        color: #ffffff;
        width: fit-content
      }
</style>
</style>
<style scoped lang="scss">
@import '@/assets/css/common.scss';
.zoom-tool-wrap {
  bottom: 20px;
}
/** 地图相关 */
#map {
  width: 960px;
  height: 500px;
  margin-top: 0px;
  position: relative;
  z-index: 1;
}
.map-container {
  position: relative;
  .total-num {
    margin-bottom: 10px;
  }
}
.status-tips-wrap {
  width: 70px;
  height: 96px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .tips-item {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tips-box {
      width: 12px;
      height: 12px;
      background: rgba(141, 255, 155, 0.4);
      border: 2px solid #b2ffb2;
    }
    .tips-box2 {
      background: rgba(24, 144, 255, 0.4);
      border: 2px solid #1890ff;
    }
    .tips-box3 {
      background: rgba(95, 152, 204, 0.4);
      border: 2px solid #5f98cc;
    }
    .tips-box4 {
      background: rgba(190, 200, 219, 0.4);
      border: 2px solid #bec8db;
    }
  }
}
</style>
